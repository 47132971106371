/* Open Sans Google Font */
@import url(https://fonts.googleapis.com/css?family=Open+Sans:600,700);

/* Montserrat Google Font */
@import url(https://fonts.googleapis.com/css?family=Montserrat:400,700);

/* Finger Paint Font */
@import url(https://fonts.googleapis.com/css?family=Alegreya+Sans+SC);

* {
  font-family: "Open Sans";
  margin: 0;
  padding: 0;
}

section {
  overflow-x: hidden;
}

.footer {
  background-size: cover;
  padding: 10px;
  margin: auto;
  color: #fff;
  background-color: #202020;
}
