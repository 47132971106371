/* Open Sans Google Font */
@import url(https://fonts.googleapis.com/css?family=Open+Sans:600,700);

/* Montserrat Google Font */
@import url(https://fonts.googleapis.com/css?family=Montserrat:400,700);

/* Finger Paint Font */
@import url(https://fonts.googleapis.com/css?family=Alegreya+Sans+SC);

* {
  font-family: "Open Sans";
  margin: 0;
  padding: 0;
}

section {
  overflow-x: hidden;
}

/* adding the banner */
.banner {
  background-size: cover;
  background-color: #202020;
  /*background-color: #01314d;*/
  color: #fff;
  padding-top: 0%;
}

.banner .containe {
  padding: 0%;
  text-align: center;
}

.banner .containe p {
  font-size: 20px;
  color: #fff;
  margin-top: -20px;
  font-family: "Montserrat";
  font-weight: 300;
  text-align: center;
}

.banner .containe img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: -40px;
  max-width: 100%;
  height: 85%;
  padding-bottom: 4%;
  margin-bottom: 10px;
}

.banner .containe h1 {
  font-size: 75px;
  color: #fff;
  padding-bottom: 10px;
  font-family: "Montserrat";
  font-weight: 700;
  text-align: center;
}

.banner .containe a {
  display: inline-block;
  border: 0.1em solid #ffffff;
  padding-top: 0.7%;
  margin: 0 0.3em 0.3em 0;
  border-radius: 0.12em;
  box-sizing: border-box;
  text-decoration: none;
  font-family: "Montserrat";
  font-weight: 300;
  font-size: 25;
  color: #ffffff;
  transition: all 0.2s;
  margin-bottom: 0px;
  text-align: center;
  width: 150px;
  height: 60px;
}

.banner .containe a:hover {
  color: #000000;
  background-color: #ffffff;
}

.banner .containe .center-buttons {
  align-items: center;
  padding-top: 1%;
}

@media (max-width: 992px) {
  .banner .containe img {
    height: 35% !important;
  }
}

.application-container {
  justify-content: center;
  display:flex;
  align-items: center !important;
}

.application {
  background-color: #004AAD !important; 
  border-color: #004AAD !important;
  width: 12.5%;
  height: 75%;
  font-size: 1.25rem !important;
  align-items: center !important;
}

.banner button {
  background-color: #0057B7 !important;
  border-color: #0057B7 !important;
  width: clamp(150px, 20vw, 350px);
  height: 75%;
  font-size: 1.25rem !important;
  align-items: center !important;
  text-align: center;
  padding: 10px 20px;
}

.banner button:hover {
  background-color: #0E4174 !important;
  border-color: #0E4174 !important;
  color: #ffffff !important;
  cursor: pointer;
  transform: translateY(-2px);
  transition: all 0.1s ease-in-out;
}
