/* Open Sans Google Font */
@import url(https://fonts.googleapis.com/css?family=Open+Sans:600,700);

/* Montserrat Google Font */
@import url(https://fonts.googleapis.com/css?family=Montserrat:400,700);

/* Finger Paint Font */
@import url(https://fonts.googleapis.com/css?family=Alegreya+Sans+SC);

* {
  font-family: "Montserrat";
  margin: 0;
  padding: 0;
}

section {
  overflow-x: hidden;
}

/* adding the about */
.about {
  background-size: cover;
  padding: 10px;
  margin: auto;
  background-color: #202020;
}

.about .container {
  border-left: 5px solid whitesmoke;
  border-width: 10px;
  border-color: #fff;
  padding: 0%;
  width: 80%;
}

.about .container img {
  display: flex;
  margin-left: auto;
  margin-right: auto;
  transition: transform 0.2s;
  width:  80px;
  height: 80px;
}

.about .container img:hover {
  transform: scale(1.2);
}

.about h1 {
  text-align: left;
  margin-top: 0.4cm;
  margin-left: 0.4cm;
  font-size: 35px;
  color: #fff;
  font-family: "Montserrat";
  font-weight: 300;
}

.about p {
  font-size: 15px;
  color: #fff;
  font-family: "Montserrat";
  font-weight: 300;
  text-align: left;
  margin-top: 0.4cm;
  margin-left: 0.4cm;
}
